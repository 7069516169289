.cta-add-location{
  min-height: 180px;
}

.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.btn-sm {
    height: 1.8rem;
    width: 1.8rem;
    font-size: 0.75rem;
  }
  &.btn-lg {
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.35rem;
  }
}

.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  .icon {
    background: fade-out($black, .85);
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
  }
  .text {
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
  }
  &.btn-sm {
    .icon {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
    .text {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
  }
  &.btn-lg {
    .icon {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
    .text {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
  }
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease 0s;
  margin-top: 42px;
  &--wrapper {
    position: relative;
    overflow: hidden;
    &:hover .overlay {
      margin-top: 0;
      transition: all .3s ease 0s;
    }
  }
}