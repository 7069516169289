.modal.panel-right .modal-dialog {
  height: 100%;
  margin: 0;
  float: right;
  border-radius: 0;
  width: 100%;
  transform: translate(250px, -0px);

  .modal-body {
    overflow: auto;
  }
}

.modal#modalConfirm {
  z-index: 2000;
}

.modal.panel-right.show .modal-dialog {
  transform: none;
}

.modal-content {
  height: 100%;
  border-radius: 0;
}