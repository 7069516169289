.card {
  .card-header {
    // Format Dropdowns in Card Headings
    .dropdown {
      line-height: 1;
      .dropdown-menu {
        line-height: 1.5;
      }
    }
  }
  // Collapsable Card Styling
  .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 1.25rem;
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 1.725rem;
      line-height: 51px;
      font-weight: 900;
      content: '\f107';
      font-family: 'Font Awesome 5 Free';
      color: $gray-400;
    }
    &.collapsed {
      border-radius: $card-border-radius;
      &::after {
        content: '\f105';
      }
    }
  }
}

.card {
  border: 0;
  border-radius: 0.75rem;
  transition: background-color 200ms ease-in;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  min-height: 50px;

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.2rem;
  }

  .card-header {
    background-color: transparent;
  }
  .card-img {
    width: 180px;
    height: 180px;
    object-fit: cover;
  }

  &.border-dashed {
    background-color: #F4F0EA;
    border: 1px dashed #E9E2D5;
  }

  &.disabled {
    opacity: .5;
  }

  &-property-resume {
    height: 100px;
    overflow: hidden;
  }

  &__room {
    width: 230px;
    height: 220px;

    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      flex-grow: 1;
    }

    &--content {
      flex-grow: 2;
    }
  }

  &--clickable {
    cursor: pointer;
  }
}

.mid-cards {
  .card {
    height: 100%;
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
