@font-face {
  font-family: bernadetteRough;
  src: url('../fonts/bernadette_rough.ttf');
}

@font-face {
  font-family: heebo;
  src: url('../fonts/Heebo-VariableFont_wght.ttf');
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-weight: 600;
}