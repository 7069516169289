.badge {
  padding: 0.5em 0.6rem 0.2rem;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.bg-primary {
  --bs-bg-opacity: .3;
  color: $primary;
}
