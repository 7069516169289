.module-big-txt {
  color: #383838;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 110.7%;
  margin: 0 0 10px 0;
}

.module-desc {
  color: #959494;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.7%;
}

.module-state {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  .module-state-icon {
    display: flex;
    width: 68px;
    height: 68px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50%;
    margin: 0 24px 0 0;
    font-size: 2em;
  }

  &.active {
    .module-state-icon {
      background: rgba(251, 93, 112, 0.05);
      color: $pink;
    }
  }

  &.deactive {
    .module-state-icon {
      background: #F5F5F5;
      color: #959494;
    }
  }

  .module-state-btn {
    display: flex;
    width: 63.808px;
    height: 27px;
    flex-shrink: 0;
    border-radius: 15.121px;
    align-items: center;

    .module-state-btn-inner {
      width: 23.396px;
      height: 23.692px;
      flex-shrink: 0;
      background: #fff;
      border-radius: 50%;
    }

    .module-state-btn-ico {
      display: flex;
      width: 26.353px;
      height: 26.353px;
      padding: 7.686px 3.294px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    &.active {
      background: #FB5D70;
      justify-content: start;

      .module-state-btn-inner {
        margin: 0 4px 0 2px;
      }
    }

    &.deactive {
      background: #383838;
      justify-content: end;

      .module-state-btn-inner {
        margin: 0 2px 0 4px;
      }
    }
  }
}

.module-item {
  padding: 24px 32px;

  .module-item-ico {
    color: $pink;
    font-size: 2em;
  }
}
