@import './variables';

@import '~bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~air-datepicker/air-datepicker.css';
@import '~choices.js/public/assets/styles/choices.min.css';
@import '~ContentTools/build/content-tools.min.css';

@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";

@import './print';
@import './fonts';
@import './defaultContentTools';
@import './login';

@import './modules/card';
@import './modules/button';
@import './modules/modal';
@import './modules/sidebar';
@import './modules/spinner';
@import './modules/badge';
@import './modules/datepicker';

@import "dropdowns.scss";
@import "navs.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";
@import "form.scss";

@import 'modules.scss';


body, html {
  font-family: 'Heebo';
  margin: 0;
  padding: 0;
  color: $gray-900;
}
* {
  box-sizing: border-box;
}

body {
  font-family: "Heebo";
  font-size: 16px;
  a{
    transition: background-color 200ms ease-in;
    &:hover {
      text-decoration: none;
      background-color: inherit;
    }
  }
  h1, h2, h3, .h1, .h2, .h3 {
    font-weight: 200;
  }
  #wrapper #content-wrapper {
    overflow-x: inherit;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    footer {
      margin-top: auto;
      height: 70px;
      display: flex;
      padding: 1rem;
    }
  }
}
table.dataTable > thead .sorting::after,
table.dataTable > thead .sorting_desc::after {
  right: 0.3em
}

.qrcode {
  font-size: 1.8rem;
  color: #FB5D70;

  h1 {font-size: 6rem;}

  .qrcode-wrapper {
    padding: 1rem;
    background-color: #FB5D70;
    border-radius: 1rem;
    color: white;
  }
  .bernadette{
    font-family: "bernadetteRough";
  }
}

.w-20{
  width: 18%;
}

.hidden {
  display: none !important;
}

#air-datepicker-global-container, .air-datepicker-global-container { z-index: 2000 }
