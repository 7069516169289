.form-inner {
    .mb-3 {
        margin-bottom: 0 !important;
    }

    .form-inner-big-label {
        width: 690px;
    }

    .form-label {
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        color: #959494;
    }

    .form-control {
        padding: 20px 16px 20px 16px;
        border-radius: 8px;
        border: 1px solid #DEDEDF;
    }

    .card {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        .card-body {
            padding: 24px 32px;
        }
    }

    .btn {
        padding: 17px;
        color: #fff;
        float: right;
        margin: 24px 0;
    }
}
