.bg-login-image {
  background: url($login-image);
  background-position: center;
  background-size: cover;
}

.bg-register-image {
  background: url($register-image);
  background-position: center;
  background-size: cover;
}

.bg-password-image {
  background: url($password-image);
  background-position: center;
  background-size: cover;
}

form.user, .login {

  .custom-checkbox.small {
    label {
      line-height: 1.5rem;
    }
  }

  .form-control-user {
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 1.5rem 1rem;
  }

  .btn-user {
    font-size: 1rem;
    border-radius: 10rem;
    padding: 0.75rem 1rem;
  }

  .btn-user, .form-control-user {
    border-radius: 0.35rem;
    border: 0;
  }

}

.login {
  color: black;

  .container {
    height: 100vh;
  }

  label {
    margin-bottom: 0;

    &.custom-control-label {
      padding-top: 3px;
    }
  }

  .card {
    box-shadow: none;
    min-height: 620px;
    max-height: 720px;
    height: 80%;

    .card-body {
      display: flex;

      .signin {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .bg-login-image {
        border-radius: .8rem;
      }
    }
  }
}