@media print {
  @page :header {
    display: none;
  }
  @page :footer {
    display: none;
  }
  a.btn {
    display: none;
  }
  footer {
    display: none;
  }

  body {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;

    max-height: 100vh;
    min-height: 100vh;
  }

  .qrcode {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;

    .qrcode-wrapper {
      background-color: $pink;
    }
  }
}

.print {
  color: $primary;
  #wrapper #content-wrapper {
    background: transparent;
    display: flex;
    width: 920px;
    margin: 0 auto;
  }
}

.qrcode {
  margin: 0 auto;
  max-width: 920px;
  footer {
    display: none;
  }
}
